<template>
  <div
    class="container-textfield"
    :class="active && extraBackgroundColor ? 'field-active' : 'field-inactive'"
    :style="{
      background: backgroundColor ? backgroundColor : '#edeff7',
      pointerEvents: active ? '' : 'none',
      border: getTextFieldBorder,
    }"
  >
    <img
      style="margin-left: 8px; width: 24px; height: 24px;"
      :src="leftIcon"
      @error="$event.target.style = 'display: none;'"
    />

    <div style="width: 100%; height: 100%; display: flex; align-items: center;">
      <input
        ref="textfield"
        type="text"
        autocomplete="off"
        class="textfield"
        :placeholder="placeholder"
        v-model="inputText"
        @blur="onBlur"
        @focus="onFocus"
      />
    </div>

    <div
      v-if="inputText.length > 0 && active"
      class="clickable"
      style="margin-right: 8px; display: flex; align-items: center;"
      @click="clearText"
    >
      <img src="@/assets/icons/ic-close-small-light.svg" style="width: 24px; height: 24px;" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'inputTextField',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    prefillText: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: true,
    },
    extraBackgroundColor: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    /**
     * A src for left icon.
     */
    leftIcon: {
      type: String,
      default: null,
    },
  },
  computed: {
    getTextFieldBorder() {
      let color = '#dbe0f0';
      if (this.isError) {
        color = '#ff335c';
      } else if (this.isFocusing) {
        color = '#4d6aff';
      }
      return `solid 2px ${color}`;
    },
  },
  data: () => ({
    inputText: '',
    isFocusing: false,
  }),
  watch: {
    prefillText() {
      this.inputText = this.prefillText;
    },
    inputText() {
      this.$emit('onTextChanged', this.inputText);
    },
  },
  methods: {
    onBlur() {
      this.isFocusing = false;
      this.$emit('onBlur');
    },
    onFocus() {
      this.isFocusing = true;
      this.$emit('onFocus');
    },
    clearText() {
      this.inputText = '';
      this.focus();
    },
    setText(text) {
      this.inputText = text;
    },
    focus() {
      this.$refs.textfield.focus();
    },
  },
  mounted() {
    this.inputText = this.prefillText;
  },
};
</script>

<style scoped>
.container-textfield {
  min-height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: #edeff7;
}

.textfield::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #9ca6c9;
}

.field-active {
  background: #dbe0f0;
}

.field-inactive {
  background: #edeff7;
}
</style>

<style lang="less" scoped>
.textfield {
  width: 100%;
  height: 100%;
  font-size: 17px;
  font-weight: 600;
  color: #263373;
  border: 0;
  background: transparent;
  box-sizing: border-box;
  padding: 0 8px;
}

input:focus {
  outline: none;
}
</style>
