
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class SearchTextField extends Vue {
  @Prop() readonly placeholder!: string | '';
  @Prop() readonly prefillText!: string | '';
  @Prop() readonly backgroundColor!: string | '';

  searchText: string = '';

  @Watch('prefillText')
  onPrefillTextChanged() {
    this.searchText = this.prefillText;
  }

  @Watch('searchText')
  onTextChanged() {
    this.$emit('onTextChanged', this.searchText);
    if (!this.searchText) {
      this.$emit('onTextCleared');
    }
  }

  onFocus() {
    this.$emit('onFocus');
  }

  onBlur() {
    this.$emit('onBlur');
  }

  onMouseUp(event: any) {
    this.$emit('onMouseUp');
    // @ts-ignore
    this.$refs.input.focus();
  }

  clearText() {
    this.searchText = '';
  }

  setText(text: string) {
    this.searchText = text;
  }

  mounted() {
    this.searchText = this.prefillText;
  }
}
