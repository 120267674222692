<template>
  <div class="clickable close-button-container" @click="onClick">
    <img src="@/assets/icons/ic-close-small-light.svg" />
  </div>
</template>

<script>
export default {
  name: 'CloseButton',
  components: {},
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style scoped>
.close-button-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #edeff7;
  overflow: hidden;
}
</style>
