// @ts-ignore
import moment from 'moment';

export function getFormattedLongThaiTime(date: Date): string {
  return date.toLocaleString('th-TH', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function toISO(date: Date): string {
  return date.toISOString();
}

const formatInt = (int: number): string => {
  if (int < 10) {
    return `0${int}`;
  }
  return `${int}`;
};

export function formattedDuration(durationSec: number): string {
  return moment(durationSec).format('mm:ss');
}

export function getLastUpdateTimeFromDateString(
  dateString: string,
  asDate: boolean,
  asBuddhistYear: boolean
) {
  const date = moment(dateString + 'Z');
  const minutes = moment().diff(date, 'minutes');
  if (asDate || minutes > 60) {
    let year = parseInt(date.format('YYYY'));
    if (asBuddhistYear) {
      year += 543;
    }
    const yearString = year.toString().slice(-2);
    const datePrefix = date.format('DD/MM/');
    const hourMinute = date.format('H:mm');

    return `${datePrefix}${yearString}, ${hourMinute}`;
  } else {
    if (minutes < 60) {
      return `${minutes + 1} นาทีที่แล้ว`;
    } else {
      return '1 ชั่วโมงที่แล้ว';
    }
  }
}
